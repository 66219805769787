import parse from 'html-react-parser'
import React from 'react'
import tw, { styled } from 'twin.macro'
import listArrow from '@images/icons/arrow-right.svg'

const Section = tw.section`max-w-screen-md mx-auto relative`
const Headline = tw.h1`relative xl:-translate-x-32 mb-8 md:mb-12 px-5 xl:px-0 break-words`
const TextWrap = tw.div`relative w-full mx-auto overflow-hidden`
const Text = styled.div`
	${tw`px-5 text-sm xl:text-copyXl xl:leading-[30px] relative`}

	h2 {
		${tw`text-3xl uppercase font-futuraExtra md:text-4xl xl:text-5xl tracking-1 mt-spacingM-master mb-spacingXs-xs`}
	}
	h3,
	h4,
	h5,
	h6 {
		${tw`text-lg uppercase font-futuraExtra md:text-xl xl:text-3xl tracking-1 mt-spacingXs-master mb-spacingXs-xs`}
	}
	p {
		${tw`pb-spacingXs-xs`}
	}

	ul {
		${tw`pb-spacingXs-xs`}
		br {
			display: none;
		}
		li {
			position: relative;
			padding-left: 1.5rem;

			&::before {
				position: absolute;
				left: 0;
				width: 0.75rem;
				height: 0.75rem;
				background-size: contain;
				top: 0.5rem;
				background-repeat: no-repeat;
				content: '';
				background-image: url(${listArrow});
			}
		}
	}
	a {
		text-decoration: underline;
		word-break: break-word;

		&:hover {
			${tw`text-primary`}
		}
	}
`

const BasicContent = ({ data }) => {
	const { content, title } = data

	return (
		<Section className='emf-pb-spacing'>
			{title && <Headline className='headline-h2 emf-pt-spacing'>{parse(title)}</Headline>}
			<TextWrap className='emf-pt-spacing'>{content && <Text>{parse(content)}</Text>}</TextWrap>
		</Section>
	)
}

export default BasicContent
